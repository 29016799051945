var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{attrs:{"title":"Icon","code-toggler":""}},[_c('p',[_vm._v("Notifications can include icons to improve the user experience and to construct a more pleasant and intuitive notification. To make this, use the "),_c('code',[_vm._v("icon")]),_vm._v(" property. A practical example would be: "),_c('code',[_vm._v("icon: 'chat'")])]),_c('vs-alert',{staticClass:"mt-5",attrs:{"color":"primary","icon":"new_releases","active":"true"}},[_c('p',[_vm._v("Vuesax uses the Google Material Icons font library by default. For a list of all available icons, visit the official "),_c('a',{attrs:{"href":"https://material.io/icons/","target":"_blank"}},[_vm._v("Material Icons page")]),_vm._v(".")]),_c('p',[_vm._v("FontAwesome and other fonts library are supported. Simply use the icon-pack with fa or fas. You still need to include the Font Awesome icons in your project.")])]),_c('div',{staticClass:"demo-alignment"},[_c('vs-button',{attrs:{"color":"primary","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
          title:'Icon mail',
          text:'Lorem ipsum dolor sit amet, consectetur',
          color:'primary',
          iconPack: 'feather',
          icon:'icon-mail'})}}},[_vm._v("Icon Mail")]),_c('vs-button',{attrs:{"color":"success","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
          title:'Icon mail',
          text:'Lorem ipsum dolor sit amet, consectetur',
          color:'success',
          iconPack: 'feather',
          icon:'icon-check'})}}},[_vm._v("Icon check_box")]),_c('vs-button',{attrs:{"color":"danger","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
          title:'Icon mail',
          text:'Lorem ipsum dolor sit amet, consectetur',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-heart'})}}},[_vm._v("Icon favorite")]),_c('vs-button',{attrs:{"color":"warning","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
          title:'Icon mail',
          text:'Lorem ipsum dolor sit amet, consectetur',
          color:'warning',
          iconPack: 'feather',
          icon:'icon-alert-circle'})}}},[_vm._v("Icon error")]),_c('vs-button',{attrs:{"color":"dark","type":"flat"},on:{"click":function($event){return _vm.$vs.notify({
          title:'Icon mail',
          text:'Lorem ipsum dolor sit amet, consectetur',
          color:'dark',
          iconPack: 'feather',
          icon:'icon-message-square'})}}},[_vm._v("Icon chat")]),_c('vs-button',{attrs:{"color":"success","type":"gradient"},on:{"click":function($event){return _vm.randomIcon()}}},[_vm._v("Icon Random Color")])],1),_c('template',{slot:"codeContainer"},[_vm._v(" <template> <div class=\"demo-alignment\"> <vs-button color=\"primary\" type=\"flat\" @click=\"$vs.notify({ title:'Icon mail', text:'Lorem ipsum dolor sit amet, consectetur', color:'primary', iconPack: 'feather', icon:'icon-mail'})\">Icon Mail</vs-button> <vs-button color=\"success\" type=\"flat\" @click=\"$vs.notify({ title:'Icon mail', text:'Lorem ipsum dolor sit amet, consectetur', color:'success', iconPack: 'feather', icon:'icon-check'})\">Icon check_box</vs-button> <vs-button color=\"danger\" type=\"flat\" @click=\"$vs.notify({ title:'Icon mail', text:'Lorem ipsum dolor sit amet, consectetur', color:'danger', iconPack: 'feather', icon:'icon-heart'})\">Icon favorite</vs-button> <vs-button color=\"warning\" type=\"flat\" @click=\"$vs.notify({ title:'Icon mail', text:'Lorem ipsum dolor sit amet, consectetur', color:'warning', iconPack: 'feather', icon:'icon-alert-circle'})\">Icon error</vs-button> <vs-button color=\"dark\" type=\"flat\" @click=\"$vs.notify({ title:'Icon mail', text:'Lorem ipsum dolor sit amet, consectetur', color:'dark', iconPack: 'feather', icon:'icon-message-square'})\">Icon chat</vs-button> <vs-button @click=\"randomIcon()\" color=\"success\" type=\"gradient\">Icon Random Color</vs-button> </div> </template> <script> export default { methods: { randomIcon() { function getRandomInt(min, max) { return Math.floor(Math.random() * (max - min)) + min; } let color = `rgb(${getRandomInt(0,255)},${getRandomInt(0,255)},${getRandomInt(0,255)})` this.$vs.notify({ title: &apos;Icon mail&apos;, text: &apos;Lorem ipsum dolor sit amet, consectetur&apos;, color: color, icon: &apos;verified_user&apos; }) } } } </script> ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }